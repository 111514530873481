@tailwind base;
@tailwind components;
@tailwind utilities;

.long-description {
    > p {
        margin-top: 32px;
        max-width: 42rem
    }

    :first-child {
        margin-top: 24px;
        font-size: 1.15rem;
        line-height: 2rem;
        max-width: 100%;
    }   
}